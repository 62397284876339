body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.spanTextOverview {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #828282;
  font-family: Manrope;
  font-family: "Manrope", sans-serif;
}
.inputSearch {
  border-color: transparent !important;
}
.inputSearch:focus-within {
  border-color: transparent !important;
  outline: none !important;
}
.inputSearch:focus-visible {
  border-color: transparent !important;
  outline: none !important;
}
.css-1oxhx2p {
  justify-content: center !important;
  align-items: center !important;
}
.popupBox {
  flex-direction: row !important;
}
@media (max-width: 790px) {
  .popupBox {
    flex-direction: column !important;
  }
}
.react-datepicker__input-container > input {
  background-color: transparent !important;
  border: 1px solid #4699d1 !important;
  padding-left: 5px !important;
}
.react-time-picker__wrapper {
  border: 1px solid #4699d1 !important;
  padding-left: 5px !important;
}
.react-time-picker__clear-button {
  display: none !important;
}
.react-time-picker__button {
  display: none !important;
}
.react-time-picker__clear-button .react-time-picker__button {
  display: none !important;
}
.chakra-radio__input{
   width: 20px !important;
   height: 20px !important;
   position: relative !important;
}
.chakra-radio__label{
  font-size: 20px !important;
}